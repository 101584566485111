import { default as React } from "react";
import { render } from "react-dom";
import * as ReStyle from "@21re/re-style/lib/barrel";
import { translations } from "./i18n";
import { Translations, ApiKind } from "./i18n/translations";


class SwaggerRoot extends React.Component {
  renderGroup(t: Translations, header: string, apis: ApiKind[], icon: JSX.Element) {
    return (
      <ReStyle.Card header={<span>{icon} {header}</span>} >
        <ReStyle.Grid columns={3} gap="md" paddingY="md" >
          <ReStyle.GridItem><strong>{t.apiKind}</strong></ReStyle.GridItem>
          <ReStyle.GridItem justifySelf="center"><strong>{t.description}</strong></ReStyle.GridItem>
          <ReStyle.GridItem justifySelf="center"><strong>{t.documentation}</strong></ReStyle.GridItem>
          {apis.map((a) => {
            return (
              <React.Fragment key={a.kind}>
                <ReStyle.GridItem colSpan={3}><hr /></ReStyle.GridItem>
                <ReStyle.GridItem>{a.kind}</ReStyle.GridItem>
                <ReStyle.GridItem>{a.description}</ReStyle.GridItem>
                <ReStyle.GridItem justifySelf="center"> <a target="_blank" href={`/swagger/${a.openapiSpecName}`}>Swagger</a> </ReStyle.GridItem>
              </React.Fragment>
            )
          })}
        </ReStyle.Grid>
      </ReStyle.Card>
    )
  }

  render() {
    const t: Translations = translations()

    return (
      <div style={{ paddingTop: "1em" }}>
        <ReStyle.Container fluid={true}>
          <ReStyle.Grid columns={1} gap="xl">
            {this.renderGroup(t, t.smartdata.name, t.smartdata.kinds, <ReStyle.Icon name="trending_up" />)}

            {this.renderGroup(t, t.residentialTools.name, t.residentialTools.kinds, <ReStyle.Icon name="build" />)}

            {this.renderGroup(t, t.testing.name, t.testing.kinds, <ReStyle.Icon name="vpn_key" />)}
          </ReStyle.Grid>
        </ReStyle.Container>
      </div>
    )
  }
}

render((<SwaggerRoot />), document.getElementById('revo-content'));

