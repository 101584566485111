import { Translations } from "./translations";
import { default as React } from "react";

export const DE: Translations = {
  apiKind: "API-Gruppierung",
  description: "Beschreibung",
  documentation: "Dokumentation (englisch)",

  smartdata: {
    name: "21st SMART DATA",
    kinds: [
      {
        kind: "Data API",
        description: <span>Liefert den vollen Zugriff auf unseren kompletten Datenschatz, von einzelnen Scores über kombinierte Profile bis hin zu Location- und Objekt-Preisen.</span>,
        openapiSpecName: "data-api-v3",
      },
    ],
  },

  residentialTools: {
    name: "Wohnen-Tools",
    kinds: [
      {
        kind: "Mietspiegel",
        description: <span>Mit dieser API kann der <em>Mietspiegel</em> abgefragt werden.</span>,
        openapiSpecName: "rentindex-api-v2",
      },
    ],
  },

  testing: {
    name: "Test-APIs",
    kinds: [
      {
        kind: "Authorisierung",
        description: <span>Test-API um sowohl die Verbindung zur 21st als auch gültige Zugangsdaten zu überprüfen.</span>,
        openapiSpecName: "api-basics",
      },
    ],
  },
}
