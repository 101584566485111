import { Translations } from "./translations";
import { default as React } from "react";

export const EN: Translations = {
  apiKind: "API kind",
  description: "Description",
  documentation: "Documentation",

  smartdata: {
    name: "21st SMART DATA",
    kinds: [
      {
        kind: "Data API",
        description: <span>Provides full access to our whole data treasure, ranging from single scores and grouped profiles to location and object prices.</span>,
        openapiSpecName: "data-api-v3",
      },
    ],
  },

  residentialTools: {
    name: "Residential Tools",
    kinds: [
      {
        kind: "Rent Index",
        description: <span>Look up and query the rent index (german <em>Mietspiegel</em>).</span>,
        openapiSpecName: "rentindex-api-v2",
      },
    ],
  },

  testing: {
    name: "Authorization/Testing",
    kinds: [
      {
        kind: "Authorization",
        description: <span>Test API to ensure authorization is handled properly.</span>,
        openapiSpecName: "api-basics",
      },
    ],
  },
}
